import React, { useState, useRef } from "react";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import {FaYoutube, FaFacebook, FaInstagram, FaGoogle, FaTwitter, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaLinkedin} from 'react-icons/fa';
import './Contact1.css';
import Navbar from "./Navbar";
import emailjs from "emailjs-com";


function Contact1() {
  const [formData, setFormData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  };

  // const validateForm = () => {
  //   let tempErrors = {};
  //   if (!formData.emailid) tempErrors.emailid = "*Please fill out this field";
  //   if (!formData.phonenumber)
  //     tempErrors.phonenumber = "*Please fill Email Option";
  //   if (!/^\d{10}$/.test(formData.phonenumber))
  //     tempErrors.phonenumber =
  //       "*Please fill 10 digit mobile number";
  //   if (!formData.ourservices || formData.ourservices === "optiont")
  //     tempErrors.ourservices = "*Please select a service";
  //   setErrors(tempErrors);
  //   return Object.keys(tempErrors).length === 0;
  // };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.emailid) {
      tempErrors.emailid = "*Please fill out this field";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailid)) {
      tempErrors.emailid = "*Invalid email address";
    }
  
    if (!formData.phonenumber) {
      tempErrors.phonenumber = "*Please fill out this field";
    } else if (!/^\d{10}$/.test(formData.phonenumber)) {
      tempErrors.phonenumber = "*Please fill 10 digit mobile number";
    }
  
    if (!formData.ourservices || formData.ourservices === "optiont") {
      tempErrors.ourservices = "*Please select a service";
    }
  
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Do nothing if form is not valid
    }

    // Check if email or phone number is already stored (assuming formData is defined elsewhere)
    if (formData.emailid === "") {
      alert("User email ID is already stored");
      return;
    }

    // Send form data via email (assuming emailjs library is used)
    const serviceID = "gmail"; // Replace with your EmailJS service ID
    const templateID = "template_id"; // Replace with your EmailJS template ID
    const userID = "d5FkrwvtLmR2kaWzj"; // Replace with your EmailJS user ID

    const templateParams = {
      username: formData.name,
      ourservice: formData.ourservices,
      emailid: formData.emailid,
      phonenumber: formData.phonenumber,
      message: formData.message,
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Thank you, our team will contact you.");
        setFormData({});
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.error("FAILED...", error);
        alert("Failed to send message. Please try again later.")
      });
    };     
    
    const handleFormSubmit = () => {
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    };

  return (
<div id="contact1">
<Navbar />
<div className="contactUsImage1">
  <div className="contactallfields">
      <div className="contact form">
        <h1>Contact Us</h1>
        {formSubmitted ? (
          // <p>Thank you, our team will contact you.🤝</p>
          <div className="after-contactform-messages">
                  <div className="after-contactform-container">
                    <p className="after-contactform-message">Thank you, our team will contact you.👍</p>
                    {/* <img src="/thankyou/istockphoto-1351008443-612x612.jpg" height="100" width="200" alt="Thank you image" className="after-contactform-image" /> */}
                  </div>
              </div>
        ) : (
          <form id="myForm" onSubmit={handleSubmit} ref={formRef}>

<div className="formBox">
<div className="row">

<div className="col-md-6 col-sm-12 contact-in">
<label>User Name</label>
<input placeholder="User Name" name="name" aria-required="true" onChange={handleChange}/>
</div>

<div className="col-lg-6 col-md-6 col-sm-12 contact-in">
<label>Our Service</label>
                      <select
                        name="ourservices"
                        onChange={handleChange}
                        className={`form-select &{errors.ourservices ? "is-invalid" : ""}`}>
                        <option value="optiont">Choose any option</option>
                        <option value="Digital marketing">Digital marketing</option>
                        <option value="Paid service">Paid Promotion Ads</option>
                        <option value="SEO">Search Engine Optimization</option>
                        <option value="SMM">Social Media Marketing</option>
                        <option value="Web development">Web development</option>
                      </select>
                      {errors.ourservices && (
                        <div className="error-text">{errors.ourservices}</div>
                      )}
</div>

<div className="col-md-6 col-lg-6 col-sm-12 contact-in">
<label>Email ID</label>
<input placeholder="E-mail" type="email" name="emailid" aria-required="true" onChange={handleChange} className={errors.emailid ? "error" : ""}/>
{errors.emailid && (
  <label className="error-text">{errors.emailid}</label>
)}
</div>



{/* <div className="col-md-6 col-sm-12 contact-in">
<label>Mobile Number</label>
<input 
        placeholder="Mobile Number" 
        type="tel" 
        name="phonenumber" 
        aria-required="true" 
        pattern="[0-9]{10}" 
        title="Only 10 digit numbers are accepted." 
        value={formData.phonenumber} 
        onChange={handleChange} 
        className={errors.phonenumber ? "error" : ""}/>
{errors.phonumber && (
  <span className="error-text">{errors.phonenumber}</span>
)}
</div> */}

<div className="col-md-6 col-sm-12 contact-in">
  <label>Mobile Number</label>
  <input
    placeholder="Mobile Number"
    type="tel"
    name="phonenumber"
    aria-required="true"
    pattern="[0-9]{10}"
    title="Only 10 digit numbers are accepted."
    value={formData.phonenumber}
    onChange={handleChange}
    className={errors.phonenumber ? "error" : ""}
  />
  {errors.phonenumber && (
    <span className="error-text">{errors.phonenumber}</span>
  )}
</div>


<div className="col-lg-12 col-md-12 col-sm-12 contact-in-message">
<label>Message</label>
<textarea name="message" id="mes" cols="20" rows="10" placeholder="message" value={formData.message || ""} onChange={handleChange} />
</div>

{/* <div className="submit col-lg-12 col-md-12 col-sm-12">
    <a className="button button1" type="submit">Submit</a>
</div> */}
<div className="submit col-lg-12 col-md-12 col-sm-12">
    <a className="button button1" onClick={handleFormSubmit}>Submit</a>
</div>

</div>
</div>
 
            </form>
          )}
        </div>
    </div>
</div>

<div className="title">
    <h1>Contact information</h1>
</div>

<div className="container">
<div className="row">
<div className="col-lg-6 col-md-12 col-sm-12 left-content-contact">
<div className="InfoBox">
  <div className="con-locationaddress d-flex">
    <span className="me-3">
      <FaMapMarkerAlt className="contact-icon" />
    </span>
    <p>
      Phoenix Marketing 2, First Floor,<br/>
      DRO Colony Main Rd, Near Indian Bank,<br/>
      Reserve Line, K.Pudur,<br/>
      Madurai, Tamil Nadu 625002.
    </p>
  </div>
  <div className="con-email">
    <span className="me-3">
      <FaEnvelope className="contact-icon"/>
    </span>
    <a href="mailto:phoenixmarketing.jp@gmail.com">phoenixmarketing.jp@gmail.com</a>
  </div>
  <div className="con-ph">
    <span className="me-3">
      <FaPhoneAlt className="contact-icon"/>
    </span>
    <a href="tel:+91 90254 28057">+91 90254 28057</a>
  </div>
  <ul className="sci">
    <li><a href="https://www.facebook.com/Phoenixmarketing.in"><FaFacebook className="contact-icon" /></a></li>
    <li><a href="https://www.youtube.com/@phoenixmarketing-in/"><FaYoutube className="contact-icon" /></a></li>
    <li><a href="https://www.instagram.com/phoenixmarketing.in/"><FaInstagram className="contact-icon" /></a></li>
    <li><a href="https://www.linkedin.com/company/phoenix-marketing-in/"><FaLinkedin className="contact-icon" /></a></li>
    {/* <li><a href="#"><FaTwitter className="contact-icon" /></a></li> */}
  </ul>
</div> 
</div>
<div className="col-lg-6 col-md-12 col-sm-12">
<div className="con-address-section">
  <div className="con-address-image d-flex justify-content-center align-items-center">
      <img src="/Imagefile/laptop 1.png" style={{ maxWidth: '100%', height: 'auto'}} />
  </div>
</div>
</div>
<div className="con map">
<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50881.46533289707!2d78.11265095682494!3d9.917855571951272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c52e5f9b0fd9%3A0xbde30152cc5503be!2sPhoenix%20Marketing!5e1!3m2!1sen!2sin!4v1707132798665!5m2!1sen!2sin"
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
></iframe>
</div>
</div>
</div>


<ScrollToTop />
<Footer />
</div>
  );
}



export default Contact1;

