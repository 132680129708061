import React, { useEffect, useState } from 'react';
import './Counter.css'

const Counter = () => {
  const [clients, setClients] = useState(0);
  const [experience, setExperience] = useState(0);
  const [projects, setProjects] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clients < 8) {
        setClients((prevClients) => prevClients + 1);
      }
      if (experience < 20) {
        setExperience((prevExperience) => prevExperience + 1);
      }
      if (projects < 5000) {
        setProjects((prevProjects) => prevProjects + 1);
      }
    }, 0); // Interval set to 50 milliseconds

    return () => clearInterval(interval);
  }, [clients, experience, projects]);

return (
<div className="counter-numbers row-blocks overtheimg">
  <div className="row counter-second">
    <div class="col-lg-4 col-md-12 col-sm-12 professional">
      <div class="counter-item1 d-flex">
        <div>
            <span class="counter-number1">{experience}+</span>
        </div>
        <div className='word-data'>
            <span class="counter-label1">Professional Members</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 experiences">
      <div class="counter-item1 d-flex">
      <div>  
        <span class="counter-number1">{clients}+</span>
      </div>
      <div className='word-data'>
        <span class="counter-label1">Years of Experience</span>
      </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 projects">
      <div class="counter-item1 d-flex">
        <div>
            <span class="counter-number1">{projects}+</span>
        </div>
        <div className='word-data'>
            <span class="counter-label1">Successfully Projects</span>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default Counter;
