import React from 'react'
import './Error.css'
import Navbar from "./Navbar";
import Footer from "./Footer";


function Error() {
  return (
    <div>
        <Navbar />
        <div className='container bg-white'>
    <div class="content-center">
      <h2 className='error-page-404'>404</h2>
      <h6>PAGE NOT FOUND</h6>
      <h6>It looks like nothing was found at this location.</h6>
      <a href="/">
      <button>BACK TO HOME</button>
      </a>
    </div>
    </div>
    <Footer />
  </div>
  )
}


export default Error
